import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import { getAgencyNameAndType } from '../../Utils/agency'
import { formatPhoneNumber, formatUrl, titleCase } from '../../Utils/Formatted'
import State from '../State'

import './agencies.scss'

class Agencies extends PureComponent {
    setLinkTo = (agency) => {
        const { match } = this.props
        const { agencyName } = getAgencyNameAndType(agency)
        if (match.params.city) return formatUrl(match.url, `${agency.id}-${agencyName}`)

        return formatUrl(match.url, `${agency.address.city}/${agency.id}-${agencyName}`)
    }

    renderPhoneNumber = (agency) => {
        if (agency.phoneNumber) {
            return (
                <span className="cn-text-default">{formatPhoneNumber(agency.phoneNumber, agency.address.country)}</span>
            )
        }

        return null
    }

    renderState = (agency) => {
        const { t } = this.props
        if (agency.state !== 'UNKNOWN') {
            return (
                <State status={agency.state}>
                    {t('common:close', { context: agency.state === 'OPEN' ? 'open' : '' })}
                </State>
            )
        }

        return null
    }

    getUrl = (id) => {
        const url = window.location.pathname.split('/')

        const country = url[1]
        const pos = url[2]

        return `${window.REACT_APP_NICKEL_PATH_ROOT}/${country === 'es' ? 'es-es' : country}/${pos}?store=${id}`
    }

    renderItem = () => {
        const { agencies = [], t } = this.props

        if (agencies.length === 0) return null
        return agencies.map((agency) => {
            const { agencyName } = getAgencyNameAndType(agency)
            return (
                <li
                    className="list-item mdc-layout-grid__cell mdc-layout-grid__cell--span-4 frame-border"
                    key={`${agencyName + agency.address.address}`}
                >
                    <a href={this.getUrl(agency.id)} className="agency">
                        <span className="title">{`${t('title:agency')} - ${agencyName}`}</span>
                        <span className="cn-text-default">
                            {titleCase(agency.address.address)}
                            &nbsp;
                            {agency.address.zipCode}
                            &nbsp;
                            {titleCase(agency.address.city)}
                        </span>
                        {this.renderPhoneNumber(agency)}
                        {this.renderState(agency)}
                    </a>
                </li>
            )
        })
    }

    render() {
        const { agencies = [], t } = this.props
        return agencies.length ? (
            <ul className="list agencies mdc-layout-grid__inner">{this.renderItem()}</ul>
        ) : (
            t('agency:no-agency-found')
        )
    }
}

Agencies.propTypes = {
    match: PropTypes.shape(),
    agencies: PropTypes.arrayOf(PropTypes.shape()),
    t: PropTypes.func
}

export default withNamespaces()(Agencies)
