import React from 'react'

import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import DepartmentListScreen from '../Containers/DepartmentListScreen'
import { getDepartmentListPath } from '../Utils/paths'

import DepartmentNavigation from './DepartmentNavigation'

const StoreLocatorNavigation = ({ match }) => {
    return (
        <Switch>
            <Route
                exact
                path={`${match.path}`}
                component={() => {
                    window.location.replace(`${window.REACT_APP_NICKEL_PATH_ROOT}${match.path}`)
                    return null
                }}
            />
            <Route exact path={`${match.path}/${getDepartmentListPath()}`} component={DepartmentListScreen} />
            <Route path={`${match.path}/:department`} component={DepartmentNavigation} />
        </Switch>
    )
}

StoreLocatorNavigation.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string
    })
}

export default StoreLocatorNavigation
