import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import './Styles/layout.scss'
import './Styles/AgencyScreen.scss'

import AgencyActions, { AgencySelectors } from '../Redux/AgencyRedux'
import { splitUrl } from '../Utils/Formatted'

class AgencyScreen extends Component {
    componentDidMount() {
        const { match } = this.props
        const params = splitUrl(match.url)
        const idAgency = params[params.length - 1]
        const split = idAgency.split('-')

        window.location.replace(this.getUrl(`${split[0]}-${split[1]}`))
    }

    getUrl = (id) => {
        const url = window.location.pathname.split('/')

        const country = url[1]
        const pos = url[2]

        return `${window.REACT_APP_NICKEL_PATH_ROOT}/${country === 'es' ? 'es-es' : country}/${pos}?store=${id}`
    }

    render() {
        return <div />
    }
}

AgencyScreen.propTypes = {
    match: PropTypes.shape()
}

const mapStateToProps = (state) => ({
    agency: AgencySelectors.getAgency(state)
})

const mapDispatchToProps = (dispatch) => ({
    getAgency: (id) => dispatch(AgencyActions.getAgencyRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(AgencyScreen))
